import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet';
import Page from '../components/Page';
import {useSiteData} from '../hooks/useSiteData';
import {graphql} from 'gatsby';

export default function Contact({data: {image, pageSeo}}) {
	const {contactUs} = useSiteData();

	return <Page>
		<Helmet>
			<meta charSet="utf-8" />
			<title>{pageSeo.PageTitle}</title>
			<meta name="description" content={pageSeo.Description} />

			<link rel="canonical" href="https://www.qxp.com.au/contact" />
		</Helmet>


		<Container fluid>
			<Row>
				<Col>
					<img
						className="d-block mw-100 mh-100"
						src={image.Image.URL}
						alt={image.AltTag}
						title={image.Title}
					/>
				</Col>
				<Col>
					<h3>Contact</h3>

					<div>{contactUs.BodyText}</div>

					<div><a href={`mailto:${contactUs.Email}`}>{contactUs.Email}</a></div>
					<div>Phone: {contactUs.Phone}</div>
					<div>Address: {contactUs.Address}</div>
					<div>ABN: {contactUs.ABN}</div>
				</Col>
			</Row>
		</Container>
	</Page>;
}

export const query = graphql`
    query {
		image(id: {eq: "6053017e-1ea9-aaca-7ca7-3a3ff343f495"}) {
			Image {
				URL
				hash
				name
			}
			Title
			AltTag 
		}
		pageSeo(id: {eq: "6088f8a0-c9b3-1002-1ada-283eaee2c83c"}) {
			PageTitle
			Description
		}
    }
`;
